<template>
  <b-modal
    id="upload-banner-modal"
    ref="upload-banner-modal"
    hide-footer
    title="อัพโหลดแบนเนอร์"
    size="lg"
    @show="setData"
    @hidden="resetModal"
    class="position-relative"
  >
    <form
      id="banner-form"
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >

      <div v-if="url" class="mb-3">
        <img :src="url" style="max-width: 100%;">
      </div>

      <b-form-group
        label="Upload file"
      >
        <b-form-file
          v-model="file"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          accept="image/jpeg, image/png, image/gif"
        ></b-form-file>
      </b-form-group>

      <b-form-group
        label="Title"
      >
        <b-form-input
          v-model="input.title"
          type="text"
          placeholder="Title"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="Link"
      >
        <b-form-input
          v-model="input.link"
          type="text"
          placeholder="https://"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="alt"
      >
        <b-form-input
          v-model="input.alt"
          type="text"
          placeholder="alt"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="Src"
      >
        <b-form-input
          v-model="input.url"
          type="text"
          placeholder="https://"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="ลำดับ"
      >
        <b-form-input
          v-model="input.sort"
          type="text"
          :number="true"
        ></b-form-input>
      </b-form-group>

      <div class="d-flex justify-content-end mt-3 pt-3">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isLoading">{{isLoading ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
      </div>
    </form>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EBEDEF"
      :height="30"
      :width="30"
      color="#007bff"
    />
  </b-modal>
</template>
<script>
import BannerService from '@/services/bannerService'
import Swal from 'sweetalert2'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'UploadBanner',
  components: {
    Loading
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: [Object, String],
      default: {
        _id: null,
        pos: '',
        title: '',
        link: '',
        alt: '',
        url: '',
        sort: 0
      }
    },
  },
  data() {
    return {
      isLoading: false,
      file: null,
      input: {
        _id: null,
        pos: '',
        title: '',
        link: '',
        alt: '',
        url: '',
        sort: 0
      }
    }
  },
  computed: {
    url() {
      if(this.file) {
        return URL.createObjectURL(this.file)
      }else
      if(this.data.url) {
        return this.data.url
      }else{
        return null
      }
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    }
  },
  methods: {
    setData() {
      this.input = {
        _id: this.data._id,
        pos: this.data.pos,
        title: this.data.title,
        link: this.data.link,
        alt: this.data.alt,
        url: this.data.url,
        sort: this.data.sort
      }
    },
    resetModal() {
      this.needReload = false
      this.file = null
      this.input = {
        _id: null,
        pos: '',
        title: '',
        link: '',
        alt: '',
        url: '',
        sort: 0
      }
    },
    showModal() {
      this.$refs['upload-banner-modal'].show()
    },
    hideModal() {
      this.$refs['upload-banner-modal'].hide()
    },
    handleSubmit() {
      // console.log(this.file)
      if(this.file) {
        this.isLoading = true

        const formData = new FormData()
        formData.append('file', this.file)
        formData.append('pos', this.input.pos)
        formData.append('title', this.input.title)
        formData.append('link', this.input.link)
        formData.append('alt', this.input.alt)
        formData.append('url', this.input.url)
        formData.append('sort', this.input.sort)

        BannerService.uploadBanner(this.data._id, formData)
        .then((response)=>{
          console.log(response)
          if(response.success) {
            this.needReload = true
            this.hideModal()
            this.$notify({
              type: 'success',
              title: 'สำเร็จ!',
              text: 'อัพโหลดไฟล์เสร็จเรียบร้อย',
            })
          }else{
            throw new Error()
          }
        })
        .catch((e)=>{
          Swal.fire({
            title: 'ผิดพลาด!',
            text: e ? e.message : 'อัพโหลดไฟล์ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        })
        .finally(()=>{
          this.isLoading = false
        })
      }else
      if(this.input.url) {
        this.isLoading = true
        BannerService.saveBanner(this.data._id, this.input)
        .then((response)=>{
          console.log(response)
          if(response.success) {
            this.needReload = true
            this.hideModal()
            this.$notify({
              type: 'success',
              title: 'สำเร็จ!',
              text: 'บันทึกเสร็จเรียบร้อย',
            })
          }else{
            throw new Error()
          }
        })
        .catch((e)=>{
          Swal.fire({
            title: 'ผิดพลาด!',
            text: e ? e.message : 'บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        })
        .finally(()=>{
          this.isLoading = false
        })
      }else{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: 'กรุณาเลือกไฟล์ หรือใส่ Url ของแบนเนอร์',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
