import api from "@/services/apiService"

class BannerService {

  uploadBanner(_id, data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'banner/upload' + (_id?`/${_id}`:''), data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  saveBanner(_id, data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'banner' + (_id?`/${_id}`:''), data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  deleteBanner(_id) {
    return new Promise((resolve, reject) => {
      api.delete(process.env.VUE_APP_BACKEND_API + 'banner/' + _id)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  getBanners(pos) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'banner/' + pos)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

}

export default new BannerService()
