<template>
  <div class="container position-relative">
    <div class="d-flex justify-content-between align-items-center mb-1">
      <strong>จัดการแบนเนอร์</strong>
      <button class="btn btn-sm btn-link" @click="addBanner">เพิ่มใหม่</button>
    </div>

    <table class="table table-bordered table-hover">
      <tbody v-for="item in items" :key="item._id">
        <tr>
          <td>
            <div class="text-primary">{{ item.title }}</div>
            <div>
              <img :src="item.url" style="max-width: 100%;">
            </div>
          </td>
          <td width="120" class="text-center">
            <b-button variant="link" size="sm" class="text-primary" @click="editBanner(item)">แก้ไข</b-button>
            <b-button variant="link" size="sm" class="text-danger" @click="deleteBanner(item._id)">ลบ</b-button>
          </td>
        </tr>
      </tbody>
      <tbody v-if="!items.length">
        <tr>
          <td class="text-center">ไม่มีข้อมูล</td>
        </tr>
      </tbody>
    </table>

    <UploadBanner :data="data" :is-show="isShowModal" @close="hideUploadModal" />

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EBEDEF"
      :height="30"
      :width="30"
      color="#007bff"
    />
  </div>
</template>
<script>
import BannerService from '@/services/bannerService'
import UploadBanner from './components/UploadBanner'
import Swal from 'sweetalert2'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'Banner',
  components: {
    UploadBanner,
    Loading
  },
  data() {
    return {
      isShowModal: false,
      isLoading: false,
      data: {
        _id: null,
        pos: '',
        title: '',
        link: '',
        alt: '',
        url: '',
        sort: 0
      },
      banners: null
    }
  },
  computed: {
    items() {
      if(!this.banners)
        return []

      return this.banners
    }
  },
  methods: {
    loadBanners() {
      this.isLoading = true
      BannerService.getBanners(this.$route.params.pos)
      .then((response)=>{
        if(response.success) {
          this.banners = response.data
        }
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    addBanner() {
      this.data = {
        _id: null,
        pos: this.$route.params.pos,
        title: '',
        link: '',
        alt: '',
        url: '',
        sort: 0
      }
      this.isShowModal = true
    },
    hideUploadModal(needReload) {
      this.isShowModal = false
      if(needReload) {
        this.loadBanners()
      }
    },
    editBanner(item) {
      this.data = {
        _id: item._id,
        pos: item.pos,
        title: item.title,
        link: item.link,
        alt: item.alt,
        url: item.url,
        sort: item.sort
      }
      this.isShowModal = true
    },
    deleteBanner(_id) {
      Swal.fire({
        title: 'ยืนยันการลบ!',
        icon: 'info',
        confirmButtonText: 'ยืนยัน',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก'
      })
      .then((res)=>{
        if(res.isConfirmed) {
          BannerService.deleteBanner(_id)
          .then((response)=>{
            if(response.success) {
              this.loadBanners()
              this.$notify({
                type: 'success',
                title: 'สำเร็จ!',
                text: 'ลบเสร็จเรียบร้อย',
              })
            }else{
              throw new Error()
            }
          })
          .catch((e)=>{
            Swal.fire({
              title: 'ผิดพลาด!',
              text: 'ลบแบนเนอร์ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
        }
      })
    }
  },
  mounted() {
    this.loadBanners()
  }
}
</script>
<style lang="scss">
.table {
  background-color: #FFFFFF;

  tr {
    td {
      vertical-align: middle;
    }
  }
}
</style>
